import type {Notification} from '~/types/types';
export const useNotificationStore = defineStore('notification', () => {
  const vehiculeStore = useVehiculeStore();
  const authentificationStore = useAuthentificationStore();

  const notifications = ref<Notification[] | null>([]);

  const getNotificationsFiltree = (filtre: string) => {
    let notificationsFiltrees;
    switch (filtre) {
      case 'nonLues': {
        notificationsFiltrees = notifications.value?.filter((notification) => notification.etat === 'NON_LUE');
        break;
      }
      case 'prioritaires': {
        notificationsFiltrees = notifications.value?.filter((notification) => notification.criticite === 'CRITIQUE');
        break;
      }
      case 'archivees': {
        notificationsFiltrees = notifications.value?.filter((notification) => notification.etat === 'ARCHIVEE');
        break;
      }
      default: {
        notificationsFiltrees = notifications.value?.filter((notification) => notification.etat !== 'ARCHIVEE');
        break;
      }
    }
    if (!notificationsFiltrees) {
      return [];
    }
    return trierNotificationsParDate(notificationsFiltrees);
  };

  const trierNotificationsParDate = (notifications: Notification[]) => {
    return notifications.sort((a: Notification, b: Notification) => {
      return new Date(b.dateNotification).getTime() - new Date(a.dateNotification).getTime();
    });
  };
  const setNotifications = (data: Notification[]) => (notifications.value = data);

  const recupererLesNotifications = async () => {
    const {data} = await useMyFetch<Notification[]>('/copilot/notifications/' + vehiculeStore.getIdActif(), {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + authentificationStore.getToken(),
      },
    });
    if (data.value) {
      setNotifications(data.value);
    }
  };

  const mettreAJourEtat = async (notification: Notification, etat: string) => {
    await useMyFetch<string>('/copilot/notifications/' + vehiculeStore.getIdActif() + '/' + notification.id, {
      method: 'PUT',
      body: etat,
      headers: {
        Authorization: 'Bearer ' + authentificationStore.getToken(),
      },
    });
    await recupererLesNotifications();
  };

  return {
    recupererLesNotifications,
    getNotificationsFiltree,
    mettreAJourEtat,
  };
});
