import * as labsComponents from 'vuetify/labs/components'

export const isDev = false
export function vuetifyConfiguration() {
  const options = JSON.parse('{"theme":{"defaultTheme":"myCustomTheme","themes":{"myCustomTheme":{"dark":false,"colors":{"error":"#D32F2F","info":"#2196F3","success":"#4CAF50","warning":"#FB8C00"}}}}}')
  
  
  options.components = {...labsComponents}
  
  return options
}

