import {initializeApp} from 'firebase/app';
import {getMessaging, getToken} from 'firebase/messaging';

const filePath = '/firebase-config.json';

export async function useFirebaseMessaging() {
  let firebaseConfig;
  const vehiculeStore = useVehiculeStore();
  try {
    const response = await fetch(filePath);
    if (!response.ok) {
      throw new Error(`Failed to fetch ${filePath}`);
    }
    firebaseConfig = await response.json();
  } catch (error) {
    console.error('Error loading Firebase config:', error);
    return;
  }

  const app = initializeApp(firebaseConfig.config);
  const messaging = getMessaging(app);

  const requestToken = async () => {
    try {
      const currentToken = await retrieveToken();

      if (currentToken) {
        vehiculeStore.abonnementNotifications(currentToken);
        return currentToken;
      } else {
        console.warn('No registration token available. Requesting permission...');
        await Notification.requestPermission();
        console.log(currentToken);
      }
    } catch (error) {
      console.error('Error retrieving token:', error);
      await Notification.requestPermission();
    }
  };

  const retrieveToken = async () => {
    return await getToken(messaging, {
      vapidKey: firebaseConfig.vapidKey,
    });
  };

  return {
    requestToken,
  };
}
