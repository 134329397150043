import { default as _4014tV1tz2bsgMeta } from "/builds/fatec-developpement/copilot/copilot-front/pages/401.vue?macro=true";
import { default as create_45accountnHQ81XjGLuMeta } from "/builds/fatec-developpement/copilot/copilot-front/pages/create-account.vue?macro=true";
import { default as demande_45reset_45passwordO1sI9bmDOCMeta } from "/builds/fatec-developpement/copilot/copilot-front/pages/demande-reset-password.vue?macro=true";
import { default as indexjEKVOHst8xMeta } from "/builds/fatec-developpement/copilot/copilot-front/pages/documents/index.vue?macro=true";
import { default as transmettre_45documentLP2ZWBJ8oiMeta } from "/builds/fatec-developpement/copilot/copilot-front/pages/documents/transmettre-document.vue?macro=true";
import { default as etat_45des_45lieuxEoidL6l0EKMeta } from "/builds/fatec-developpement/copilot/copilot-front/pages/etat-des-lieux.vue?macro=true";
import { default as immobilisation_45vehiculez9OeqXGf1UMeta } from "/builds/fatec-developpement/copilot/copilot-front/pages/immobilisation-vehicule.vue?macro=true";
import { default as indexNHC8BHmKeOMeta } from "/builds/fatec-developpement/copilot/copilot-front/pages/index.vue?macro=true";
import { default as loginEMwbf1bdW8Meta } from "/builds/fatec-developpement/copilot/copilot-front/pages/login.vue?macro=true";
import { default as mon_45compterN9tZLJ9p8Meta } from "/builds/fatec-developpement/copilot/copilot-front/pages/mon-compte.vue?macro=true";
import { default as mon_45vehiculeExhRhAxuwmMeta } from "/builds/fatec-developpement/copilot/copilot-front/pages/mon-vehicule.vue?macro=true";
import { default as notificationsAbo3TtBq88Meta } from "/builds/fatec-developpement/copilot/copilot-front/pages/notifications.vue?macro=true";
import { default as prochaines_45visitesbD9OBX7VN4Meta } from "/builds/fatec-developpement/copilot/copilot-front/pages/prochaines-visites.vue?macro=true";
import { default as _91prestation_93ISkw0Wh7ZKMeta } from "/builds/fatec-developpement/copilot/copilot-front/pages/que-faire/commentaire/[prestation].vue?macro=true";
import { default as indexnuwojSMHPyMeta } from "/builds/fatec-developpement/copilot/copilot-front/pages/que-faire/index.vue?macro=true";
import { default as rechercheVcHZhq1nEgMeta } from "/builds/fatec-developpement/copilot/copilot-front/pages/recherche-prestataire/recherche.vue?macro=true";
import { default as releve_45kilometriqueZctBtySwNVMeta } from "/builds/fatec-developpement/copilot/copilot-front/pages/releve-kilometrique.vue?macro=true";
import { default as reparation_45carrosserie3QkkVKqCVuMeta } from "/builds/fatec-developpement/copilot/copilot-front/pages/reparation-carrosserie.vue?macro=true";
import { default as reset_45passwordH2KNcS0t5AMeta } from "/builds/fatec-developpement/copilot/copilot-front/pages/reset-password.vue?macro=true";
import { default as tutorielLtnc6MivOdMeta } from "/builds/fatec-developpement/copilot/copilot-front/pages/tutoriel.vue?macro=true";
import { default as vehiculesrkqcpRzK9qMeta } from "/builds/fatec-developpement/copilot/copilot-front/pages/vehicules.vue?macro=true";
export default [
  {
    name: "401",
    path: "/401",
    component: () => import("/builds/fatec-developpement/copilot/copilot-front/pages/401.vue").then(m => m.default || m)
  },
  {
    name: "create-account",
    path: "/create-account",
    meta: create_45accountnHQ81XjGLuMeta || {},
    component: () => import("/builds/fatec-developpement/copilot/copilot-front/pages/create-account.vue").then(m => m.default || m)
  },
  {
    name: "demande-reset-password",
    path: "/demande-reset-password",
    meta: demande_45reset_45passwordO1sI9bmDOCMeta || {},
    component: () => import("/builds/fatec-developpement/copilot/copilot-front/pages/demande-reset-password.vue").then(m => m.default || m)
  },
  {
    name: "documents",
    path: "/documents",
    meta: indexjEKVOHst8xMeta || {},
    component: () => import("/builds/fatec-developpement/copilot/copilot-front/pages/documents/index.vue").then(m => m.default || m)
  },
  {
    name: "documents-transmettre-document",
    path: "/documents/transmettre-document",
    meta: transmettre_45documentLP2ZWBJ8oiMeta || {},
    component: () => import("/builds/fatec-developpement/copilot/copilot-front/pages/documents/transmettre-document.vue").then(m => m.default || m)
  },
  {
    name: "etat-des-lieux",
    path: "/etat-des-lieux",
    component: () => import("/builds/fatec-developpement/copilot/copilot-front/pages/etat-des-lieux.vue").then(m => m.default || m)
  },
  {
    name: "immobilisation-vehicule",
    path: "/immobilisation-vehicule",
    meta: immobilisation_45vehiculez9OeqXGf1UMeta || {},
    component: () => import("/builds/fatec-developpement/copilot/copilot-front/pages/immobilisation-vehicule.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/fatec-developpement/copilot/copilot-front/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginEMwbf1bdW8Meta || {},
    component: () => import("/builds/fatec-developpement/copilot/copilot-front/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "mon-compte",
    path: "/mon-compte",
    meta: mon_45compterN9tZLJ9p8Meta || {},
    component: () => import("/builds/fatec-developpement/copilot/copilot-front/pages/mon-compte.vue").then(m => m.default || m)
  },
  {
    name: "mon-vehicule",
    path: "/mon-vehicule",
    component: () => import("/builds/fatec-developpement/copilot/copilot-front/pages/mon-vehicule.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: notificationsAbo3TtBq88Meta || {},
    component: () => import("/builds/fatec-developpement/copilot/copilot-front/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: "prochaines-visites",
    path: "/prochaines-visites",
    meta: prochaines_45visitesbD9OBX7VN4Meta || {},
    component: () => import("/builds/fatec-developpement/copilot/copilot-front/pages/prochaines-visites.vue").then(m => m.default || m)
  },
  {
    name: "que-faire-commentaire-prestation",
    path: "/que-faire/commentaire/:prestation()",
    meta: _91prestation_93ISkw0Wh7ZKMeta || {},
    component: () => import("/builds/fatec-developpement/copilot/copilot-front/pages/que-faire/commentaire/[prestation].vue").then(m => m.default || m)
  },
  {
    name: "que-faire",
    path: "/que-faire",
    component: () => import("/builds/fatec-developpement/copilot/copilot-front/pages/que-faire/index.vue").then(m => m.default || m)
  },
  {
    name: "recherche-prestataire-recherche",
    path: "/recherche-prestataire/recherche",
    meta: rechercheVcHZhq1nEgMeta || {},
    component: () => import("/builds/fatec-developpement/copilot/copilot-front/pages/recherche-prestataire/recherche.vue").then(m => m.default || m)
  },
  {
    name: "releve-kilometrique",
    path: "/releve-kilometrique",
    meta: releve_45kilometriqueZctBtySwNVMeta || {},
    component: () => import("/builds/fatec-developpement/copilot/copilot-front/pages/releve-kilometrique.vue").then(m => m.default || m)
  },
  {
    name: "reparation-carrosserie",
    path: "/reparation-carrosserie",
    component: () => import("/builds/fatec-developpement/copilot/copilot-front/pages/reparation-carrosserie.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordH2KNcS0t5AMeta || {},
    component: () => import("/builds/fatec-developpement/copilot/copilot-front/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "tutoriel",
    path: "/tutoriel",
    meta: tutorielLtnc6MivOdMeta || {},
    component: () => import("/builds/fatec-developpement/copilot/copilot-front/pages/tutoriel.vue").then(m => m.default || m)
  },
  {
    name: "vehicules",
    path: "/vehicules",
    meta: vehiculesrkqcpRzK9qMeta || {},
    component: () => import("/builds/fatec-developpement/copilot/copilot-front/pages/vehicules.vue").then(m => m.default || m)
  }
]